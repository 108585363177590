/* eslint-disable react/prop-types */

import React, { Component } from "react";
import Glide from "@glidejs/glide";
import GlideArrows from "~components/GlideArrows";
import GlideBullets from "~components/GlideBullets";

class GlideCarouselComponent extends Component {
  componentDidMount() {
    setTimeout(() => {
      const glide = new Glide(`.${this.props.glideClass}`, this.glideOptions());

      if (this.props.onRun) {
        glide.on(`run`, () => this.props.onRun(glide));
      }

      glide.mount();
    });
  }

  glideOptions = () => {
    let peek = { before: 0, after: 0 };

    if (this.props.glidePeek) {
      peek = this.props.glidePeek;
    }

    let perView = 1;

    if (this.props.glidePerView) {
      perView = this.props.glidePerView;
    }

    return {
      autoplay: 3000,
      hoverPause: true,
      gap: 0,
      perView,
      peek,
      type: `slider`
    };
  };

  //

  render() {
    const {
      arrowPadding,
      bulletsMargin,
      className,
      children,
      colorClass,
      glideClass,
      slideData
    } = this.props;

    return (
      <>
        <div
          className={`glide ${glideClass} ${className ||
            ``} flex items-center flex-wrap`}
        >
          <div className="glide__track pb-4" data-glide-el="track">
            <ul className="glide__slides">{children}</ul>
          </div>

          <GlideArrows arrowPadding={arrowPadding} />

          <GlideBullets
            bulletsMargin={bulletsMargin}
            colorClass={colorClass}
            data={slideData}
          />
        </div>
      </>
    );
  }
}

const GlideCarousel = props => {
  const {
    arrowPadding,
    bulletsMargin,
    children,
    className,
    colorClass,
    glideClass,
    glidePeek,
    glidePerView,
    onRun,
    slideData
  } = props;

  return (
    <GlideCarouselComponent
      arrowPadding={arrowPadding}
      bulletsMargin={bulletsMargin}
      className={className}
      colorClass={colorClass}
      glideClass={glideClass}
      glidePeek={glidePeek}
      glidePerView={glidePerView}
      onRun={onRun}
      slideData={slideData}
    >
      {children}
    </GlideCarouselComponent>
  );
};

export default GlideCarousel;
