/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";

import { AppContext } from "~context/AppContext";

class HeaderComponent extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  //

  render() {
    return (
      <>
        <header className="header relative w-full flex flex-row justify-between items-center text-dark-grey z-10 p-8 xs:p-4 bg-light-grey">
          {this.props.appContext.device !== `mobile` ? (
            <Link to="/" className="flex flex-row items-baseline">
              <span className="f3 pr-6" role="img" aria-label="Camera emoji">
                📷
              </span>

              <span className="f3-header font-bold">JB Photohouse</span>
            </Link>
          ) : (
            <Link to="/" className="flex flex-row items-baseline">
              <span className="f3 pr-2" role="img" aria-label="Camera emoji">
                📷
              </span>
              <span className="f3-header font-bold">JB</span>
            </Link>
          )}
          <div className="flex flex-row">
            <Link to="/" className="f4 pr-8" activeClassName="underline">
              Home
            </Link>

            <Link to="/contact" className="f4" activeClassName="underline">
              Contact
            </Link>
          </div>
        </header>
      </>
    );
  }
}

const Header = () => {
  return (
    <AppContext.Consumer>
      {appContext => <HeaderComponent appContext={appContext} />}
    </AppContext.Consumer>
  );
};

export default Header;
