/* eslint-disable react/prop-types */

import React from "react";

const GlideBullets = props => {
  const { bulletsMargin, colorClass, data } = props;

  return (
    <ul
      className={`glide__bullets flex flex-row w-full items-center justify-center ${bulletsMargin ||
        ``} ${colorClass ? `glide__bullets--${colorClass}` : ``}`}
      data-glide-el="controls[nav]"
    >
      {data.map((entry, index) => {
        const entryIndex = index;

        return (
          <li key={`bullet-${entryIndex}`} className="glide__bullet mt-4 mx-1">
            <button type="button" data-glide-dir={`=${entryIndex}`}>
              {entryIndex}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default GlideBullets;
