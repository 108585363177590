/* eslint-disable react/prop-types */

import React from "react";

import SVGArrow from "~components/svg/SVGArrow";

const GlideArrows = props => {
  const { arrowPadding } = props;

  return (
    <div
      className="glide__arrows m-auto absolute top-0 bottom-0 left-0 right-0 flex items-center justify-between pointer-events-none"
      data-glide-el="controls"
    >
      <button
        type="button"
        className="glide__arrow glide__arrow--left pointer-events-auto"
        data-glide-dir="<"
      >
        <div className="relative block">
          <SVGArrow />
        </div>
      </button>

      <button
        type="button"
        className="glide__arrow glide__arrow--right pointer-events-auto"
        data-glide-dir=">"
      >
        <div className="relative block">
          <SVGArrow />
        </div>
      </button>
    </div>
  );
};

export default GlideArrows;
